import * as React from 'react'
import { useLocation } from "@reach/router"
import Helmet from "react-helmet"

const Head = () => {

  const locationHref = useLocation().href
  const locationPathname = useLocation().pathname;
  // console.log(locationHref);
  // console.log(locationPathname);

  let title = "【公式HP】ル・サンク小田原栄町/神奈川県小田原市/新築分譲マンション/株式会社NIPPO";
  let description = "進化する小田原、その中心に象徴となる住まいを。小田原の標。ＪＲ線・小田急小田原線「小田原」駅 東口徒歩5分、相模湾を臨む、全戸南向き・全65邸の新築分譲マンション誕生。資料受付開始";
  let keywords = "ル・サンク小田原栄町,ルサンク小田原栄町,新築マンション,分譲マンション,JR線,小田急小田原線,ル・サンク,南向き,小田原,NIPPO,小田原駅";
  let ogSiteName = "【公式HP】ル・サンク小田原栄町";
  let ogTitle;
  let ogDescription = description;
  let ogType;
  let ogLocale = "ja_JP";
  let ogUrl = locationHref;
  let ogImage = "https://lc-odawara-sakaecho.jp/ogimage/ogimage.jpg";
  let thumbnail = "https://lc-odawara-sakaecho.jp/ogimage/ogimage_sp.jpg";

  if (locationPathname === "/") {
    title = "【公式HP】ル・サンク小田原栄町/神奈川県小田原市/新築分譲マンション/株式会社NIPPO";
    ogTitle = title;
    ogType = "website";
  } else if (locationPathname === "/concept/") {
    title = "コンセプト｜【公式HP】ル・サンク小田原栄町/神奈川県小田原市/新築分譲マンション/株式会社NIPPO";
    ogTitle = title;
    ogType = "article";
  } else if (locationPathname === "/attractiveness/") {
    title = "東口の魅力｜【公式HP】ル・サンク小田原栄町/神奈川県小田原市/新築分譲マンション/株式会社NIPPO";
    ogTitle = title;
    ogType = "article";
  } else if (locationPathname === "/access/") {
    title = "アクセス｜【公式HP】ル・サンク小田原栄町/神奈川県小田原市/新築分譲マンション/株式会社NIPPO";
    ogTitle = title;
    ogType = "article";
  } else if (locationPathname === "/design/") {
    title = "デザイン｜【公式HP】ル・サンク小田原栄町/神奈川県小田原市/新築分譲マンション/株式会社NIPPO";
    ogTitle = title;
    ogType = "article";
  } else if (locationPathname === "/plan/") {
    title = "間取り｜【公式HP】ル・サンク小田原栄町/神奈川県小田原市/新築分譲マンション/株式会社NIPPO";
    ogTitle = title;
    ogType = "article";
  } else if (locationPathname === "/plan/b-type/") {
    title = "Btype｜【公式HP】ル・サンク小田原栄町/神奈川県小田原市/新築分譲マンション/株式会社NIPPO";
    ogTitle = title;
    ogType = "article";
  } else if (locationPathname === "/plan/e-type/") {
    title = "Etype｜【公式HP】ル・サンク小田原栄町/神奈川県小田原市/新築分譲マンション/株式会社NIPPO";
    ogTitle = title;
    ogType = "article";
  } else if (locationPathname === "/company/") {
    title = "会社紹介｜【公式HP】ル・サンク小田原栄町/神奈川県小田原市/新築分譲マンション/株式会社NIPPO";
    ogTitle = title;
    ogType = "article";
  } else if (locationPathname === "/map/") {
    title = "現地案内図｜【公式HP】ル・サンク小田原栄町/神奈川県小田原市/新築分譲マンション/株式会社NIPPO";
    ogTitle = title;
    ogType = "article";
  }

  return (
    <>
      <Helmet>
        <html lang="ja" prefix="og: https://ogp.me/ns#" />
        <title>{title}</title>
        <meta http-equiv="Content-Style-Type" content="text/css" />
        <meta http-equiv="Content-Script-Type" content="text/javascript" />
        <meta name="format-detection" content="telephone=no" />
        <meta name="robots" content="index, follow" />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="thumbnail" content={thumbnail} />
        <meta property="og:locale" content={ogLocale} />
        <meta property="og:site_name" content={ogSiteName} />
        <meta property="og:description" content={ogDescription} />
        <meta property="og:image" content={ogImage} />
        <meta property="og:url" content={ogUrl} />
        <meta property="og:type" content={ogType} />
        <meta property="og:title" content={ogTitle} />
      </Helmet>
    </>
  )
}
export default Head