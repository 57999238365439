import * as React from 'react'
import { useState } from 'react';
import { Head, Header, Nav, FloatNav, Footer } from '@inc/index'

const Layout = ({ children, }) => {

  const [open, setOpen] = useState(false)
  const toggle = () => {
    setOpen(!open)
  }
  const close = () => {
    setOpen(false)
  }

  return (
    <>
      <Head />
      <Header />
      {/* <Nav
        toggle={toggle}
        open={open}
      /> */}
      <FloatNav
        close={close}
      />
      {children}
      <Footer />
    </>
  )
}
export default Layout