module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-M786T9Q","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-226124500-1"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://lc-odawara-sakaecho.jp/","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"odawara","short_name":"odawara","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"standalone","icon":"static/favicons/favicon.png","icon_options":{"purpose":"any maskable"},"icons":[{"src":"/favicons/android-chrome-36x36.png","sizes":"36x36","type":"image/png"},{"src":"/favicons/android-chrome-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/favicons/android-chrome-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/favicons/android-chrome-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/favicons/android-chrome-128x128.png","sizes":"128x128","type":"image/png"},{"src":"/favicons/android-chrome-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/favicons/android-chrome-152x152.png","sizes":"152x152","type":"image/png"},{"src":"/favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/android-chrome-256x256.png","sizes":"256x256","type":"image/png"},{"src":"/favicons/android-chrome-384x384.png","sizes":"384x384","type":"image/png"},{"src":"/favicons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b9b2584ca7bcacc2b0432ab299d91c44"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
